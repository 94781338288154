import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IToast, ToastPosition } from './toast.types';
import { UtilsService } from '@gci/utils';

@Injectable({
    providedIn: "root",
})
export class ToastService {
    private _toastInfo: BehaviorSubject<IToast[]> = new BehaviorSubject<IToast[]>([]);

    constructor(
        private _utils: UtilsService
    ) { }

    get toastInfo$() {
        return this._toastInfo.asObservable();
    }

    set toastInfo(info: IToast[]) {
        this._toastInfo.next(info);
    }


    error(msg: string) {
        let toastNow = this._toastInfo.getValue();
        toastNow.push({
            id: this._utils.randomId(),
            type: "error",
            activeTime: 5000, // waktu dalam milidetik
            msg: msg,
            active: true,
            onHover: false,
        });
        this._toastInfo.next(toastNow);
    }

    success(msg: string) {
        let toastNow = this._toastInfo.getValue();
        toastNow.push({
            id: this._utils.randomId(),
            type: "success",
            activeTime: 5000, // waktu dalam milidetik
            msg: msg,
            active: true,
            onHover: false,
        });
        this._toastInfo.next(toastNow);

    }

    info(msg: string) {
        let toastNow = this._toastInfo.getValue();
        toastNow.push({
            id: this._utils.randomId(),
            type: "info",
            activeTime: 5000, // waktu dalam milidetik
            msg: msg,
            active: true,
            onHover: false,
        });
        this._toastInfo.next(toastNow);
    }

    warnings(msg: string) {
        let toastNow = this._toastInfo.getValue();
        toastNow.push({
            id: this._utils.randomId(),
            type: "warning",
            activeTime: 5000, // waktu dalam milidetik
            msg: msg,
            active: true,
            onHover: false,
        });
        this._toastInfo.next(toastNow);
    }

    cutes(msg: string) {
        let toastNow = this._toastInfo.getValue();
        toastNow.push({
            id: this._utils.randomId(),
            type: "cute",
            activeTime: 5000, // waktu dalam milidetik
            msg: msg,
            active: true,
            onHover: false,
        });
        this._toastInfo.next(toastNow);
    }
}
